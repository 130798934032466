<template>
    <div class="page">
        <full-page ref="fullpage" :options="options">
        <div class="purpose section fade-in ">
            <div class="intro">
                <h1 class="fade-in"> {{ translate[language].intro.title }} </h1>
                <ul>
                    <li class="fade-in animation" :class="[ 'delay-'+index ]" v-for='index of translate[language].intro.lists.length - 1' :key="index"> {{ translate[language].intro.lists[index] }} </li>
                </ul>
            </div>
             <div class="option-navigation text-center fade-in delay-6 " :class="{'bounce-top' : enableInterval }" >
                <a href="#" @click="$refs.fullpage.api.moveSectionDown()" class="center margin-auto"> 
                    <img src="/images/scroll-icon.png" alt="" class="inverted block margin-auto">
                </a>
            </div>
        </div>
        <div class="row options purpose section fade-in">
            <div v-show="activeOption == 'options'" >
                <h2 :class="{'slide-bottom' : activeSection==1 }" v-html="translate[language].ecosystem.title "> </h2>
                    <div  class="option-box-container">
                        <div class="col-md-2 option "  :class="{'scale-in-center animation delay-1' : activeSection == 1 }" @click="activeOption = 'social'" >
                            <img src="/images/icon1.png" alt="">
                            <div class="option-text ecosystem" > {{ translate[language].ecosystem.item1 }}  </div>
                        </div>
                        <div class="col-md-2 option" :class="{'scale-in-center animation delay-2' : activeSection == 1 }"  @click="activeOption = 'wealth'">
                            <img src="/images/icon2.png" alt="">
                            <div class="option-text ecosystem"> {{ translate[language].ecosystem.item2 }}  </div>
                        </div>
                    </div>
            </div>
            <div class="options wealth " v-show="activeOption == 'wealth'" >
                <h2 :class="{'slide-bottom' : activeSection==1 }" v-html="translate[language].wealth.title ">  </h2>
                <div class="option-box-container">
                    <div class="option-image margin-auto">
                        <img class="fade-in  bold animation delay-1" src="/images/icon-blocks.png" >
                        <div class="fade-in  bold animation delay-2" style="position: absolute; bottom: 90px; left: -90px;"> {{ translate[language].wealth.option1 }} </div>
                        <div class="fade-in  bold animation delay-3" style="position: absolute; bottom: 20px; left: -50px;"> {{ translate[language].wealth.option2 }} </div>
                        <div class="fade-in  bold animation delay-4"> {{ translate[language].wealth.option3 }} </div>
                        <div class="fade-in  bold animation delay-5" style="right: -63px; position: absolute; bottom: 20px;"> {{ translate[language].wealth.option4 }} </div>
                        <div class="fade-in  bold animation delay-6" style="position: absolute; bottom: 90px; right: -90px;"> {{ translate[language].wealth.option5 }} </div>
                    </div>
                </div>
            </div>
            <div class="options wealth" v-show="activeOption == 'social'" >
                <h2 :class="{'slide-bottom' : activeSection==1 }" v-html=" translate[language].transform.title "> </h2>
                    <div class="container-fluid">
                    <div  class="option-box-container">
                        <div class=" option rounded" :class="{'fade-in animation delay-1' : activeSection == 1 }">
                            <div class="option-text " v-html=" translate[language].transform.item1"></div>
                            <img src="/images/icon-tree.png" alt="" class="image-first" >
                            <div class="option-spacer scale-left animation delay-2 visible-md visible-lg hidden-xs hidden-sm"></div>
                        </div>
                        <div class=" option rounded" :class="{'fade-in animation delay-2' : activeSection == 1 }">
                            <div class="option-text " v-html="translate[language].transform.item2">  <br> <br></div>
                            <img src="/images/icon-money.png" alt="" class="image-second"  >
                            <div class="option-spacer scale-left animation delay-3 visible-md visible-lg hidden-xs hidden-sm"></div>
                        </div>
                        <div class=" option rounded" :class="{'fade-in animation delay-3' : activeSection == 1 }">
                            <div class="option-text " v-html="translate[language].transform.item3"> </div>
                            <img src="/images/icon-build.png" alt="" class="image-third" >
                            <div class="option-spacer scale-left animation delay-4 visible-md visible-lg hidden-xs hidden-sm"></div>
                        </div>
                        <div class=" option rounded" :class="{'fade-in animation delay-5' : activeSection == 1 }">
                            <div class="option-text " v-html="translate[language].transform.item4"> <br> <br> </div>
                            <img src="/images/icon-parkcity.png" class="image-last"   >
                        </div>
                    </div>
                </div>
            </div>
            <div class="option-navigation fade-in delay-6" :class="{'bounce-top' : enableInterval }" >
                <a href="#" @click="$refs.fullpage.api.moveSectionUp()" v-if="activeOption == 'options'" class="block margin-auto fade-in"> 
                    <img src="/images/scroll-icon.png" alt="Up">
                </a>
                 <a href="#" @click="selectOption('options')" v-if="activeOption != 'options'" class="block margin-auto fade-in"> 
                    <img src="/images/scroll-icon.png" alt="Up" style="brightness(0) invert(1)">
                </a>
            </div>
        </div>
       </full-page>
    </div>
</template>
<script>
import { setInterval } from 'timers';

export default {
    data(){
        return {
            activeOption: '',
            activeSection : 0,
            slideDirection : 'down',
            enableInterval: false,
            language : this.$cookies.get('language') == null ? 'english' : this.$cookies.get('language'),
            disabled : true,
            options: {
                afterLoad: this.afterLoad,
                scrollBar: false,
                licenseKey: ''
            },
            translate : {
                spanish: {
                     wealth: {
                        title : "Destinamos parte de nuestra utilidad para el mejoramiento del entorno y calidad de vida de las personas",
                        option1 : 'Educación',
                        option2 : 'Cultura',
                        option3 : 'Entorno',
                        option4 : 'Deporte',
                        option5 : 'Salud'
                    },
                    intro: {
                        title: 'NUESTRO PROPÓSITO',
                        lists : [
                                '',
                                'EL PRIMER 16% DE RETORNO ES DE LOS INVERSIONISTAS Y DESPUÉS TODOS JUNTOS GANAREMOS 25% COMO OBJETIVO DE RETORNO NETO',
                                'Nuestro objetivo es que todos ganemos',
                                'Generamos certidumbre invirtiendo en proyectos con retornos sólidos en mercados probados',
                                'Sabemos encontrar oportunidades en tiempos de crisis',
                                'Generamos valor a nuestros inversionistas',
                                'Generamos riqueza y el bien comÚn',
                            ]
                    },
                    ecosystem :{
                        title : "NUESTRO <span class='bold-gold'>COMPROMISO</span>",
                        item1 : 'Generación de Riqueza',
                        item2 : 'Transformación Social',
                    },
                    transform : {
                        title : "DESEAMOS GENERAr UNA <span class='bold-gold'> transformación positiva </span>",
                        item1 : 'Sustentabilidad en ecosistemas',
                        item2 : 'Activación económica del entorno',
                        item3 : 'Innovación en métodos de construcción',
                        item4 : 'Inversión en la localidad',
                    }
                },
                english: {
                    wealth: {
                        title : "WE <span class='bold-gold'> ALLOCATE EARNINGS</span> TO IMPROVE THE ENVIRONMENT AND THE COMMUNITIES",
                        option1 : 'Education',
                        option2 : 'Culture',
                        option3 : 'Environment',
                        option4 : 'Sports',
                        option5 : 'Health'
                    },
                    intro: {
                        title: "HAN'S PURPOSE",
                        lists : [
                                '',
                                'The first 16% return is yours (investors) and we all win more onwards add net return goal of 25%',
                                'Our Objective is that everyone wins',
                                'We create certainty by investing in projects with solid returns in proven markets',
                                'We know how to find opportunities in challenging times',
                                'We create value for our investors',
                                'We generate wealth and prosperity',
                            ]
                    },
                    ecosystem :{
                        title : 'WHAT IS <span class="bold-gold" >OUR ECOSYSTEM?</span>',
                        item1 : 'Wealth Creation',
                        item2 : 'Social Transformation',
                    },
                    transform : {
                        title : "WE WANT TO IGNITE A <span class='bold-gold' > POSITIVE TRANSFORMATION </span>",
                        item1 : 'Environmental sustainability',
                        item2 : 'Economic <br> activation',
                        item3 : 'Innovation in construction methods',
                        item4 : 'Local <br>investment',
                    }

                }
            }
        }
    },

     
    methods:{
         afterLoad( origin, destination, direction){
            this.activeSection = destination.index
            this.slideDirection = direction
            this.activeOption = ( destination.item == 0 ? ''   : 'options' )
        },
        selectOption( optionString ){
            this.activeOption = optionString
        },
        
        intervalBounce(){
             var self = this
                setInterval( function(){
                    if ( self.enableInterval ){
                        self.enableInterval = false
                    } else {
                        self.enableInterval = true
                    }
                }, 3000)
        },
        checkLanguage(){
            let self = this 
            setInterval( function(){
                self.language = ( self.$cookies.get('language') == null ? 'english' : self.$cookies.get('language')  )   // return value      
            }, 1000 )
        },
    },
    created(){
        this.checkLanguage()
    }
}
</script>


